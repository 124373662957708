/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import EmailIcon from 'Component/EmailIcon/EmailIcon.component';
import { NewsletterSubscriptionComponent as SourceNewsletterSubscriptionComponent } from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';
import { ReactElement } from 'Type/Common.type';

import './NewsletterSubscription.override.style';

/** @namespace Poprawa/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscriptionComponent {
    renderFormBody(): ReactElement {
        const { isLoading } = this.props;

        return (
            <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                <EmailIcon />
                { super.renderFormBody() }
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
